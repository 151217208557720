import React, { memo, useMemo } from 'react';
import { getAppliedRadius, getInventoryListTrackingData } from 'site-modules/shared/utils/inventory/srp-utils';

export const withListTrackingData = Component => {
  const ListTrackingData = memo(props => {
    const { inventoryData, visitorLocation, selectedFacets, urlContext } = props;

    const radius = useMemo(() => getAppliedRadius({ inventoryData, visitorLocation, selectedFacets }), [
      inventoryData,
      selectedFacets,
      visitorLocation,
    ]);
    const listTrackingData = useMemo(
      () =>
        getInventoryListTrackingData({
          radius,
          inventoryData,
          selectedFacets,
          urlContext,
          isSrp: true,
        }),
      [inventoryData, radius, selectedFacets, urlContext]
    );

    return <Component {...props} listTrackingData={listTrackingData} />;
  });

  ListTrackingData.displayName = `withListTrackingData(${Component.name || Component.displayName})`;

  return ListTrackingData;
};
